/* eslint-disable import/no-cycle */
/* eslint-disable import/extensions */
import {
  useState,
  useMemo,
  useCallback,
  useRef,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ModuleRegistry } from '@ag-grid-community/core';
import { MasterDetailModule } from '@ag-grid-enterprise/master-detail';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
// eslint-disable-next-line import/no-duplicates
import { LicenseManager } from 'ag-grid-enterprise';
// eslint-disable-next-line import/no-duplicates
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import config from './ag-config.properties.json';
import { DataGridMain, DataGridOverview } from '.';
import './DataGrid.css';

/* eslint-disable */

const { API_KEY, grid } = config;

ModuleRegistry.registerModules([ClientSideRowModelModule, MasterDetailModule, RowGroupingModule]);
LicenseManager.setLicenseKey(API_KEY);

const defaultGridHeight = grid.main.HEIGHT;

export const DataGrid = (props) => {
  /** (see link for data-grid options reference): https://ag-grid.com/react-data-grid/grid-options/ */
  const {
    /** Native AG-Grid Props */
    autoGroupColumnDef={},
    editable=false,
    filter=false,
    headerTooltip=null,
    lockPinned=false,
    onGridReady=null,
    onRowDataUpdated = null,
    onSelectionChanged = null,
    pagination=false,
    paginationPageSize=grid.main.PAGINATION_SIZE,
    paginationPageSizeSelector=grid.main.PAGINATION_PAGE_SIZE_SELECTOR,
    resizable=false,
    rowClassRules,
    suppressMovableColumns=false,
    suppressRowClickSelection=true,
    sortable=false,
    tooltipValueGetter=null,
    /** Custom DataGrid Props */
    columns=[],
    customFooterElement=null,
    datagridOverviewProperties={
      columns: [],
      disableHighlightOnOverviewBookedColumn: false,
      rows: [],
      sharedColumnOverviewProperties: null,
      width: grid.overview.WIDTH,
    },
    disableHighlightOnBookedColumn=false,
    enableMultipleColumnSorting=false,
    enableMultipleRowSelection=false,
    gridHeight=defaultGridHeight,
    gridHeightBuffer=0, // Subtracts to the value of gridHeightFromRoot to make the data grid height longer.
    loading=false,
    paginationRowLength=-1,
    rows=[],
    customVerbiage="No Rows to Show",
    sharedColumnProperties=null,
    showAlternativeRowHighlight=false,
    showDataGridOverview=false,
    useCheckboxForRowSelection=false,
    ...rest
  } = props;

  const mainWrapperRef = useRef(null);
  const overviewGridRef = useRef(null);
  const mainGridRef = useRef(null);
  const overviewGridWrapperRef = useRef();

  // state variable declarations
  const [autoGroupColumnDefinition, setAutoGroupColumnDefinition] = useState(autoGroupColumnDef)
  const [columnDefinition, setColumnDefinition] = useState(columns);
  const [columnOverviewDefinition, setColumnOverviewDefinition] = useState(datagridOverviewProperties?.columns);
  const [gridHeightFromRoot, setGridHeightFromRoot] = useState(defaultGridHeight);
  const [gridIsLoading, setGridIsLoading] = useState(true);
  const [isMainGridReady, setIsMainGridReady] = useState(false);
  const [isVerticalScroll, setIsVerticalScroll] = useState(false);
  const [pageSize, setPageSize] = useState(paginationPageSize);
  const [rowData, setRowData] = useState(rows);
  const [rowsToDisplay, setRowsToDisplay] = useState(rows.length);
  const [selectedRows, setSelectedRows] = useState([]);

  const mainGridDefaultColumnProperties = useMemo(() => {
    return {
      editable,
      filter,
      minWidth: grid.general.COLUMN_MIN_WIDTH,
      resizable,
      sortable,
      lockPinned,
    };
  }, [resizable, sortable, editable]);

  const applyCellClassMethod = useCallback((params) => {
    /**
     * This function determines the CSS class for the cell in an ag-grid column based on the row's selection status.
     * If the row is selected, the cell background color is set to the specified selected row background color (--ag-selected-row-background-color).
     * If the row is not selected, the cell background color alternates between two designated colors (--op2mise-color-ash-gray) and --op2mise-color-light-gray.
     * Prevents overlapping of styles betweeen the --ag-selected-row-background-color and the 'booked' auto cell styling
     * */
    const selectedRowBackgroundColor = getComputedStyle(document.documentElement).getPropertyValue('--ag-selected-row-background-color');
    const bookedColumnStyle = (backgroundColor) => {
      return { 'textAlign': 'right', 'backgroundColor': backgroundColor, 'boxShadow': '3px 0px 4px rgba(0, 0, 0, 0.10) inset' };
    }
    if (params) {
      if (params.node.isSelected()) {
        return { ...bookedColumnStyle(selectedRowBackgroundColor) }
      } else {
        return params.rowIndex % 2 !== 0 ? bookedColumnStyle('var(--op2mise-color-ash-gray)') : bookedColumnStyle('var(--op2mise-color-light-gray)');
      }
    }
  }, []);

  const handleCapitalization = useCallback((columnInstance) => {
    /**
     * On initial load, this function maps through the list of column definitions and capitalizes the field/header names of each column.
     */
    return columnInstance.map(column => {
      if (column.children && column.children.length) {
        column.children.map(child => {
          if (child.headerName) {
            child['headerName'] = child.headerName.toUpperCase();
          } else {
            child['headerName'] = child.field.toUpperCase();
          }
          return child;
        });
      }
      return {
        ...column,
        ...(column.headerName ? { headerName: column.headerName.toUpperCase() } : { headerName: column.field.toUpperCase() }),
      };
    });
  }, []);
  
  const handleChildColumnHeaders = useCallback((columnInstance) => {
    /**
     * On initial load, this function maps through the column definitions to check if one or more columns define contains a child column.
     * If a column has children, it provides a headerClass named 'ag-header-child', which is responsible for creating
     * a different cell styling for each child column header.
     */
    return columnInstance.map(c => {
      if (c.children && c.children.length) {
        // column has children object
        c.children.map(child => {
          child['headerClass'] = 'ag-header-child';
          return child;
        });
      }
      return c;
    });
  }, []);

  useEffect(() => {
    /**
     * Tracks changes in rows and sets it to rowData to
     * Replenish/Refresh data in DataGrid
     */
    setRowData(rows);
    mainGridRef.current?.api?.refreshCells({ force: true });

    // Update number of rows displayed
    if (paginationRowLength > 0) {
      setRowsToDisplay(paginationRowLength);
    } else {
      setRowsToDisplay(rows.length);
    }

    // Dynamically set a new page size if number of rows start to exceed 200
    if (rows.length > 200) {
      const newPageSize = (Math.ceil(rows.length / 100) * 100);
      setPageSize(newPageSize);
    };

  }, [rows]);

  const noRowsOverlayComponent = useCallback(() => (
    <div className="ag-overlay-no-rows-wrapper">
        <div className="ag-overlay-no-rows-panel">
          {typeof customVerbiage !== 'function' ? (
            <span className="ag-overlay-no-rows-center">{customVerbiage}</span>
          ) : (
            customVerbiage()
          )}
        </div>
    </div>
  ), []);

  return (
    //customGrid is a class without any styles to dynamically add styles to grid using DOM
    <div className="customGrid" ref={mainWrapperRef} id='mainGridWrapper'>
      <div className="customGrid" id='mainGridWrapperChild'>
        {(showDataGridOverview && !gridIsLoading) && (
          <DataGridOverview {...{
            applyCellClassMethod,
            columnDefinition,
            columnOverviewDefinition,
            datagridOverviewProperties,
            handleCapitalization,
            handleChildColumnHeaders,
            isVerticalScroll,
            loading,
            mainGridRef,
            mainWrapperRef,
            overviewGridRef,
            overviewGridWrapperRef,
            setColumnOverviewDefinition,
            setGridHeightFromRoot,
            showDataGridOverview,
          }}/>
        )}
        <DataGridMain {...{
          applyCellClassMethod,
          autoGroupColumnDef,
          autoGroupColumnDefinition,
          setAutoGroupColumnDefinition,
          columns,
          columnDefinition,
          defaultColumnProperties: mainGridDefaultColumnProperties,
          disableHighlightOnBookedColumn,
          enableMultipleColumnSorting,
          enableMultipleRowSelection,
          gridHeightFromRoot,
          gridHeightBuffer,
          gridIsLoading,
          handleCapitalization,
          handleChildColumnHeaders,
          isMainGridReady,
          loading,
          mainGridRef,
          mainGridRestProps: {...rest},
          noRowsOverlayComponent,
          onGridReady,
          onRowDataUpdated,
          onSelectionChanged,
          overviewGridRef,
          pageSize,
          pagination,
          rowData,
          rowClassRules,
          setColumnDefinition,
          customVerbiage,
          setGridHeightFromRoot,
          setGridIsLoading,
          setIsMainGridReady,
          setIsVerticalScroll,
          setRowData,
          setRowsToDisplay,
          sharedColumnProperties,
          showAlternativeRowHighlight,
          suppressMovableColumns,
          suppressRowClickSelection,
          useCheckboxForRowSelection,
        }} />
        {(!pagination && !gridIsLoading && isMainGridReady) && (
          <div className="ag-footer-no-pagination">
            <span>Showing {!rowsToDisplay ? '0' : '1'} to {rowsToDisplay} of {rowsToDisplay}</span>
            {customFooterElement && <div>{customFooterElement(mainGridRef)}</div>}
          </div>
        )}
      </div>
    </div>
  );
};

DataGrid.propTypes = {
  /** Native Ag-Grid Props **/
  editable: PropTypes.bool,
  filter: PropTypes.bool,
  headerTooltip: PropTypes.string,
  resizable: PropTypes.bool,
  rowClassRules: PropTypes.object,
  pagination: PropTypes.bool,
  paginationPageSize: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  suppressRowHoverHighlight: PropTypes.bool,
  suppressMovableColumns: PropTypes.bool,
  sortable: PropTypes.bool,
  tooltipValueGetter: PropTypes.func,

  /** Custom Props **/
  columns: PropTypes.array.isRequired,
  customFooterElement: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.func,
  ]),
  datagridOverviewProperties: PropTypes.object,
  disableHighlightOnBookedColumn: PropTypes.bool,
  enableMultipleColumnSorting: PropTypes.bool,
  enableMultipleRowSelection: PropTypes.bool,
  gridHeight: PropTypes.number,
  rows: PropTypes.array.isRequired,
  sharedColumnProperties: PropTypes.object,
  showAlternativeRowHighlight: PropTypes.bool,
  showDataGridOverview: PropTypes.bool,
  rest: PropTypes.object, // handle any other non-defined properties
};
