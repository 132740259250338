export const applyFilterParams = ({ columnInstance }) => {
   /**
    * This function is designed to configure filter parameters for each column in an ag-Grid configuration.
    * It iterates through the columns and applies specific filter parameters, such as enabling the mini filter to
    * apply while typing and displaying tooltips. This enhances the user experience by providing real-time
    * filtering feedback and additional information through tooltips, making data filtering more intuitive and
    * efficient.
    */
   
  return columnInstance.map((column) => ({
    ...column,
    filterParams: {
      ...column.filterParams,
      applyMiniFilterWhileTyping: true,
      showTooltips: true,
    },
  }))
};
