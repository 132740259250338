export const onCellMouseDownEvent = ({ params, onCellMouseDown }) => {
  const { event, colDef, node } = params;

  // Check if the column is a grouped column
  const isRowGroup = colDef.showRowGroup;

  // Check if the click happened on a group row's chevron icon
  const chevronIcon = event.target.closest('.ag-icon');

  // If the chevron icon is not clicked, exit
  if (!chevronIcon) return;

  // Execute onCellMouseDown externally, if provided
  if (onCellMouseDown) onCellMouseDown(params);

  // Prevent row selection if the chevron icon is clicked
  if (isRowGroup && chevronIcon) {
    
    // Check if the chevron is currently expanded or collapsed
    const isCollapsed = chevronIcon.classList.contains('ag-icon-tree-closed');
    const isExpanded = chevronIcon.classList.contains('ag-icon-tree-open');

    // Toggle the expansion state based on the chevron's current state
    if (isCollapsed) {
      // Expand the group
      node.setExpanded(true);
    } else if (isExpanded) {
      // Collapse the group
      node.setExpanded(false);
    }
  }
};