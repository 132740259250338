export const gridRowSortHelper = (
  state,
  defaultSort,
  baseGridRef,
  columnDefinition
) => {
  if (!state.sort && defaultSort) {
    const columnDefWithSort = columnDefinition.map((col) => {
      if (col.field === defaultSort.field) {
        col['sort'] = defaultSort.sort;
      }

      return col;
    });
    baseGridRef.current?.api?.setGridOption('columnDefs', columnDefWithSort);
  }
};
