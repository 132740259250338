export const gridStateHelper = (gridState, gridRef) => {
  /**
  * GRID STATE APPLIED:
  * aggregation: NO
  * columnGroup: NO
  * columnOrder: YES;
  * columnPinning: YES;
  * columnSizing: YES;
  * columnVisibility: YES;
  * filter: YES;
  * focusedCell: NO;
  * pagination: NO;
  * pivot: NO;
  * rangeSelection: NO;
  * rowGroup: NO;
  * rowGroupExpansion: NO;
  * rowSelection: NO;
  * scroll: NO;
  * sideBar: NO;
  * sort: YES;
  */
 const columnSizingModel = gridState?.columnSizing?.columnSizingModel || [];
 const columnSortModel = gridState?.sort?.sortModel || [];
 const columnVisibility = gridState?.columnVisibility?.hiddenColIds || null;
 const columnPinning = gridState?.columnPinning || null;

 const arrayMerger = (...arrays) => {
   const mergedMap = new Map();
 
   // Iterate through each array
   arrays.forEach(array => {
     array.forEach(item => {
       const { colId, ...attributes } = item;
 
       // Check if the colId already exists in the mergedMap
       if (mergedMap.has(colId)) {
         // Merge attributes if colId exists
         const existingItem = mergedMap.get(colId);
         Object.assign(existingItem, attributes);  // Combine attributes, overwriting duplicates
       } else {
         // Add new item to the map
         mergedMap.set(colId, { colId, ...attributes });
       }
     });
   });
 
   // Convert the map values back to an array
   return Array.from(mergedMap.values());
 };

 const state = arrayMerger(columnSizingModel, columnSortModel).map(column => {
   if(columnVisibility && gridState.columnVisibility?.hiddenColIds.includes(column.colId)) {
     column['hide'] = true;
   }
   if (columnPinning) {
     columnPinning.leftColIds.forEach(pinnedCols => {
       if (pinnedCols === column.colId) column['pinned'] = 'left';
     });
     columnPinning.rightColIds.forEach(pinnedCols => {
       if (pinnedCols === column.colId) column['pinned'] = 'right';
     });
   }
   return column;
 });

 gridRef?.current?.api?.applyColumnState({
   state,
   applyOrder: true, // APPLIES COLUMN ORDER
 });

 // ROW FILTER MODEL APPLICATION
 if (gridState.filter) gridRef?.current?.api?.setFilterModel(gridState.filter.filterModel);
};