import BootstrapSpinner from "shared/components/bootstrap-spinner/BootstrapSpinner";

export const LoaderTemplateForRow = ({ isLoading, height }) => {
  return isLoading && (
    <div
      style={{
        width: '100%',
        height,
        zIndex: 4,
        // borderRadius: '6px',
        background: 'var(--op2mise-color-white)',
        position: 'absolute',
        bottom: 0,
        left: 0,
      }}
    >
      <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
        <BootstrapSpinner />
      </div>
    </div>
  )
}