import useBasePresentationService from "./useBasePresentationService";

export const usePostPresentationService = () => {
  const { isLoading, response, error, callApi } = useBasePresentationService();

  const saveApi = (url, data, queryParams = {}, headers = {}) => {
    const queryString = new URLSearchParams(queryParams).toString();
    const endpoint = queryString ? `${url}?${queryString}` : url;
    callApi(endpoint, "POST", data, headers);
  };

  return { isLoading, response, error, saveApi };
};
