/* eslint-disable no-prototype-builtins */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable import/prefer-default-export */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import * as React from 'react';
import { AgGridReact } from 'ag-grid-react';
import BootstrapSpinner from 'shared/components/bootstrap-spinner/BootstrapSpinner';
import config from '../../op-datagrid.config.json';
import {OverlayTemplate} from '../../Templates'
import '../../stylesheets/ag-theme-op2mise-overview.css';

export function OverviewGrid(props) {
  const {
    applyCellClassMethod,
    columnDefinition,
    columnOverviewDefinition,
    datagridOverviewProperties,
    loading,
    baseGridRef,
    mainWrapperRef,
    overviewGridRef,
    overviewGridWrapperRef,
    handleCapitalization,
    handleChildColumnHeaders,
    showDataGridOverview,
    setColumnOverviewDefinition,
  } = props;

  const { grid } = config;
  const [overviewGridIsLoading, setOverviewGridIsLoading] = React.useState(true);
  const [isHeaderWithChildren, setIsHeaderWithChildren] = React.useState(false);
  const [viewPortHeight, setViewPortHeight] = React.useState(0);

  const dataGridOverviewStyle = React.useMemo(() => ({
    height: datagridOverviewProperties.rows.length < 2 ? isHeaderWithChildren ? '106px' : '76px' : '100%',
    width: datagridOverviewProperties.width || '100%',
    marginBottom: 20,
    boxShadow: '0 10px 20px rgba(0,0,0,.1)',
    position: 'relative',
  }), [datagridOverviewProperties.width, isHeaderWithChildren]);

  const handleOverviewBooked = React.useCallback((columnInstance) => {
    /**
     * On initial load, this function maps through the list of columns to identify a column with a field or name resembling 'Booked'.
     * If such column is found, it utilizes the applyCellClassMethod function to apply necessary cell styles for cells within the 'Booked' column.
     * The cell styles basically applies two shades of gray (light and dark) as well as auto text alignment to `right`.
     */
    const { disableHighlightOnOverviewBookedColumn } = datagridOverviewProperties;
    return columnInstance.map((column) => {
      if (column.children && column.children.length) {
        setIsHeaderWithChildren(true);
        column.children.map((child) => {
          if (child.field.includes('book') && !disableHighlightOnOverviewBookedColumn) {
            child.cellStyle = applyCellClassMethod;
          }
          return child;
        });
      }
      return column;
    });
  }, [datagridOverviewProperties.disableHighlightOnOverviewBookedColumn, applyCellClassMethod]);

  const handleOverviewSharedColumnProperties = React.useCallback((columnInstance) => {
    /**
     * This function is designed to identify and collect shared properties among columns in an ag-Grid configuration.
     * It iterates through the apply properties across multiple columns.
     * This is useful for optimizing column configurations and ensuring consistency in the application's data presentation.
     */
    const overviewProps = datagridOverviewProperties.sharedColumnOverviewProperties;
    return columnInstance.map((column) => {
      if (overviewProps) {
        Object.keys(overviewProps).forEach((key) => {
          if (column.children && column.children.length) {
            column.children.map((child) => {
              if (overviewProps[key].fields.includes(child.field) && overviewProps) {
                child[key] = overviewProps[key].value;
              }
              return child;
            });
          }
          if (overviewProps[key].fields.includes(column.field) && overviewProps) {
            column[key] = overviewProps[key].value;
          }
        });
      }
      return column;
    });
  }, [datagridOverviewProperties.sharedColumnOverviewProperties]);

  const handleOverviewAlignedColumns = React.useCallback((columnInstance) => {
    let totalWidth = 0;
    let columnsWithoutWidthProperties = 0;
    let columnWidthForAlignedGrids = 0;

    if (mainWrapperRef.current) {
      // gets the actual width of the grid in px
      totalWidth = mainWrapperRef.current.offsetWidth;
    }

    const newColumnDef = columnDefinition.map((column) => {
      if (column.children && column.children.length) {
        columnsWithoutWidthProperties -= 1; // Needs to subtract 1 for the parent column
        column.children.map((child) => {
          if (child.width) {
            totalWidth -= column.width;
          } else {
            columnsWithoutWidthProperties += 1;
          }
          return child;
        });
      }
      if (column.width) {
        totalWidth -= column.width;
      } else {
        columnsWithoutWidthProperties += 1;
      }
      return column;
    });
    columnWidthForAlignedGrids = (totalWidth / columnsWithoutWidthProperties) - 4.25; // Needs to subtract 2.25px as buffer

    return columnInstance.map((overviewGridColumnItem) => {
      const findAlignedColumns = newColumnDef.find((column) => column.field === overviewGridColumnItem.field);
      if (findAlignedColumns) {
        if (findAlignedColumns.children && findAlignedColumns.children.length && overviewGridColumnItem.children && overviewGridColumnItem.children.length) {
          overviewGridColumnItem.children = overviewGridColumnItem.children.map((child) => {
            const childInstance = { ...child };
            const alignedChild = findAlignedColumns.children.find((alignedChild) => alignedChild.hasOwnProperty('width'));
            if (alignedChild) {
              childInstance.width = alignedChild.width;
            } else {
              childInstance.width = columnWidthForAlignedGrids;
            }
            return childInstance;
          });
          return overviewGridColumnItem;
        } if (!findAlignedColumns.hasOwnProperty('width')) {
          overviewGridColumnItem.width = findAlignedColumns.width;
          overviewGridColumnItem.minWidth = findAlignedColumns.width;
          return {
            ...overviewGridColumnItem,
            width: columnWidthForAlignedGrids,
          };
        }
        return {
          ...overviewGridColumnItem,
          width: findAlignedColumns.width,
        };
      }
      return overviewGridColumnItem;
    });
  }, []);

  const handleColumnProperties = React.useCallback(() => {
    const columnInstance = datagridOverviewProperties.columns;
    const functionsToApply = [
      handleOverviewBooked,
      handleCapitalization,
      handleChildColumnHeaders,
      handleOverviewSharedColumnProperties,
      handleOverviewAlignedColumns,
    ];
    const modifiedColumnOverviewDefinition = functionsToApply.reduce((column, func) => func(column), columnInstance);
    setColumnOverviewDefinition(modifiedColumnOverviewDefinition);
    setOverviewGridIsLoading(false);
  }, [
    datagridOverviewProperties.columns,
    handleOverviewBooked,
    handleCapitalization,
    handleChildColumnHeaders,
    handleOverviewSharedColumnProperties,
    handleOverviewAlignedColumns,
  ]);

  const onOverviewGridReady = (params) => {
    const computedStyle = window.getComputedStyle(overviewGridWrapperRef.current);
    const totalHeight = overviewGridWrapperRef.current.offsetHeight + parseInt(computedStyle.marginTop)
      + parseInt(computedStyle.marginBottom);

    const element = document.querySelector('.ag-body-viewport.ag-row-animation.ag-layout-normal');
    if (element) {
      const height = element.clientHeight;
      setViewPortHeight(height);
    }

    params.api.sizeColumnsToFit();
  };

  React.useEffect(() => {
    handleColumnProperties();
  }, [datagridOverviewProperties.columns]);

  React.useEffect(() => {
    overviewGridRef.current?.api?.setGridOption('columnDefs', columnOverviewDefinition);
  }, [columnOverviewDefinition]);

  return (
    <React.Fragment>
      <div ref={overviewGridWrapperRef} className="ag-theme-op2mise-overview" style={dataGridOverviewStyle}>
        {loading && (
          // Shows grid loader for a milli-second to allow data-grid to fully render first before showing
          <div
            style={{
              width: '100%',
              height: '100%' || viewPortHeight,
              zIndex: 2,
              borderTopLeftRadius: '6px',
              borderTopRightRadius: '6px',
              background: 'var(--op2mise-color-white)',
              position: 'absolute',
              bottom: 0,
              left: 0,
            }}
          >
            <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
              <BootstrapSpinner />
            </div>
          </div>
        )}
        <AgGridReact
          alignedGrids={[baseGridRef]}
          columnDefs={columnOverviewDefinition}
          defaultColDef={{
            filter: false,
            editable: false,
            minWidth: grid.general.COLUMN_MIN_WIDTH,
            resizable: false,
            sortable: false,
            suppressHeaderMenuButton: true,
            suppressMovable: true,
          }}
          onGridReady={onOverviewGridReady}
          noRowsOverlayComponent={() => (<OverlayTemplate {...{ customVerbiage: datagridOverviewProperties.customVerbiage || 'No rows to show' }} />)}
          popupParent={document.querySelector('body')}
          rowData={datagridOverviewProperties.rows}
          ref={overviewGridRef}
          {...grid.overview.DEFAULT}
          {...(datagridOverviewProperties.rows.length > 1 && { domLayout: 'autoHeight' })}
        />
      </div>
    </React.Fragment>
  );
}
