import React from "react";

export const FooterTemplate = (props) => {
  const { loading, pagination, rowsToDisplay, customFooterElement, baseGridRef } = props;

  return !pagination && (
    <div className="ag-footer-no-pagination">
      {loading ? (
        <span>Showing 0 to 0 of 0</span>
      ) : (
        <span>Showing {!rowsToDisplay ? '0' : '1'} to {rowsToDisplay} of {rowsToDisplay}</span>
      )}
      {customFooterElement && <div>{customFooterElement(baseGridRef)}</div>}
    </div>
  );
};
