/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/prop-types */
import React from 'react';
import { isEmpty } from 'lodash';
import InputLabel from '../../Forms/InputLabel';
// eslint-disable-next-line import/no-cycle
import {allow1Decimal, allow1Point, allow2Decimals, formatDecimal, replaceWithInt} from '../helpers/helpers';

function MarketSpendFields({
                             name, onChange,
                             values,
                             errors,
                             index,
                             setFieldValue,
                             budget,
                             isCampaignActive,
                           }) {
  const error = isEmpty(errors) ? {} : errors.markets[index];

  const handleChange = (e) => {
      if(e.target.name.includes('allocation')) {
          let val = e.target.value;
          if (val !== '') {
              val = parseFloat((budget * val) / 100) || 0;
          }
          setFieldValue(`markets[${index}].budgetAmount`, val);
      }

      if(e.target.name.includes('budgetAmount')) {
          let val = e.target.value;
          if (val !== '') {
              val = parseFloat((val / budget) * 100).toFixed(1);
          }
          setFieldValue(`markets[${index}].allocation`, val);
      }
    onChange(e);
  };

  const handleDecimal = (e, name) => {
    let val = e.target.value;
    const maxValue = 100;
    const decimalOnly = ['reach', 'frequency'];
    
    if (decimalOnly.includes(name))  {
        val = val.replace(/^0+/, '');
        if (val === '.0') return;
    }

    if (val.length > 1) val = val.replace(/^0+/, '');

    if (val > maxValue) return;
      val = val.replace('..', '.');

    if (val.includes('.'))  {
      const valSplit = val.split('.');
      if (valSplit[0] === '') val = `0${val}`;
    }

    e.target.value = val.replace(/^00+/, 0);
    e.target.value = allow1Point(allow1Decimal(e, val));
    handleChange(e);
  };

  const handleReachInput = (e, name) => {
    let val = e.target.value;
    const maxValue = 100;
    const decimalOnly = ['reach', 'frequency'];

    if (decimalOnly.includes(name))  {
        val = val.replace(/^0+/, '');
        if (val === '.0') return;
    }

    if (val.length > 1) val = val.replace(/^0+/, '');

    if (val > maxValue) return;
    val = val.replace('..', '.');

    if (val.includes('.')) {
      const valSplit = val.split('.');
      if (valSplit[0] === '') val = `0${val}`;
    }

    e.target.value = val.replace(/^00+/, 0);
    e.target.value = allow1Point(allow2Decimals(e, val));
    handleChange(e);
  };

  const handleAllocationInput = (e, name) => {
    let val = e.target.value;
    const maxValue = 100;

    if (val.length > 1) val = val.replace(/^0+/, '');

    if (val > maxValue) return;
    val = val.replace('..', '.');

    if (val.includes('.')) {
      const valSplit = val.split('.');
      if (valSplit[0] === '') val = `0${val}`;
    }

    e.target.value = val.replace(/^00+/, 0);
    e.target.value = allow1Point(allow2Decimals(e, val));
    handleChange(e);
  }

    const handleTrimNumeric = (val) => {
        if (val.includes('.')) {
            // eslint-disable-next-line no-param-reassign,prefer-destructuring
            val = val.split('.')[0];
        }
        return replaceWithInt(val.replace(/\D/g, ''));
    };

  const handleNumeric = (e) => {
      e.target.value = handleTrimNumeric(e.target.value);

      handleChange(e);
  };

  return (
      <div className="d-inline-flex align-items-center r-container gap-2" style={{ width: '100%'}}>
        <div className="d-inline-flex gap-2" style={{ width: '100%'}}>
            <div className="d-inline-flex align-items-center" style={{ width: '30%'}}>
                <span style={{ overflow: 'hidden', textOverflow: 'ellipsis',  whiteSpace: 'nowrap' }}>{name}</span>
            </div>
            <div className="d-inline-flex justify-content-center" style={{ width: '12%'}}>
                <InputLabel className="text-align" maxLength={5} name={`markets[${index}].allocation`} value={values[index].allocation} onChange={handleAllocationInput} disabled={!isCampaignActive} />
            </div>
            <div className="d-inline-flex justify-content-center" style={{ width: '12%'}}>
                <InputLabel className="text-align" maxLength={11} name={`markets[${index}].budgetAmount`} value={formatDecimal(values[index].budgetAmount)}  onChange={handleNumeric} disabled={!isCampaignActive} />
            </div>
            <div className="d-inline-flex justify-content-center" style={{ width: '12%'}}>
                <InputLabel errors={error} errorName={'frequency'} className="text-align" maxLength={4} name={`markets[${index}].frequency`} value={values[index].frequency} onChange={(e) => handleDecimal(e, 'frequency')} disabled={!isCampaignActive} />
            </div>
            <div className="d-inline-flex justify-content-center" style={{ width: '12%'}}>
                <InputLabel errors={error} errorName={'reach'} className="text-align" maxLength={5} name={`markets[${index}].reach`} value={values[index].reach} onChange={(e) => handleReachInput(e, 'reach')} disabled={!isCampaignActive} />
            </div>
            <div className="d-inline-flex justify-content-center" style={{ width: '12%'}}>
                <InputLabel errors={error} errorName={'costPerPoint'} className="text-align" maxLength={4} name={`markets[${index}].costPerPoint`} value={formatDecimal(values[index].costPerPoint)} onChange={handleNumeric} disabled={!isCampaignActive} />
            </div>
            <div className="d-inline-flex justify-content-center" style={{ width: '12%'}}>
              <InputLabel errors={error} errorName={'targetImpressions'} className="text-align" maxLength={11} name={`markets[${index}].targetImpressions`} value={formatDecimal(values[index].targetImpressions)} onChange={handleNumeric} disabled={!isCampaignActive} />
            </div>
        </div>
      </div>
  );
}

export default MarketSpendFields;