export const applyStyleOnChildHeaderCell = ({ columnInstance }) => {
  /**
   * Applies a specific CSS class to child column headers in an ag-Grid configuration.
   * This function iterates through the column definitions to identify columns with child columns,
   * and assigns a 'headerClass' named 'ag-header-child' to each child column header.
   *
   * @param {Array} columnInstance - The list of column objects to process.
   * @returns {Array} The updated list of columns with applied header classes.
   **/

  return columnInstance.map(column => {
    // Check if the column has children
    if (column.children && column.children.length) {
      // Iterate over each child column
      column.children = column.children.map(child => {
        // Assign the CSS class 'ag-header-child' to the header of the child column
        child['headerClass'] = 'ag-header-child';
        return child; // Return the updated child column
      });
    }
    return column; // Return the column with updated child columns
  });
}