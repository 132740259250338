export const applySharedColumnProperties = ({ columnInstance, sharedColumnProperties }) => {
  /**
   * This function is designed to identify and collect shared properties among columns in an ag-Grid configuration.
   * It iterates through the apply properties across multiple columns.
   * This is useful for optimizing column configurations and ensuring consistency in the application's data presentation.
   *
   * @param {Array} columnInstance - The list of column objects to process.
   * @param {Object} sharedColumnProperties - An object containing properties to be applied, where each property has:
   *   - `fields`: An array of field names that the property applies to.
   *   - `value`: The value to be applied to the matching fields.
   * @returns {Array} The updated list of columns with applied shared properties.
   **/

  return columnInstance.map((column) => {
    // Check if `sharedColumnProperties` is provided
    if (sharedColumnProperties) {
      // Iterate over each property in `sharedColumnProperties`
      Object.keys(sharedColumnProperties).forEach((key) => {
        // Check if the column has children
        if (column.children && column.children.length) {
          // Apply properties to child columns
          column.children = column.children.map((child) => {
            // Check if the child's field is in the list of fields for the current property
            if (sharedColumnProperties[key].fields.includes(child.field) && sharedColumnProperties) {
              // Apply the shared property value to the child column
              child[key] = sharedColumnProperties[key].value;
            }
            return child; // Return the updated child column
          });
        }

        // Check if the column's field is in the list of fields for the current property
        if (sharedColumnProperties[key].fields.includes(column.field) && sharedColumnProperties) {
          // Apply the shared property value to the column
          column[key] = sharedColumnProperties[key].value;
        }
      });
    }

    return column; // Return the updated column
  });
}