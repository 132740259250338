export const applyVerticalColumns = ({ columnInstance, setGridHasVerticalColumns }) => {
  const columnIsVertical = columnInstance.some(
    (column) => column.field?.includes('week') && column.field?.includes('-vertical')
  );

  setGridHasVerticalColumns(columnIsVertical);

  if (!columnIsVertical) return columnInstance;

  return columnInstance.map((column) => {
    if (column.field?.includes('week') && column.field?.includes('-vertical')) {
      column['headerClass'] = 'ag-header-week-vertical';
    } else {
      column['headerClass'] = 'ag-header-none-vertical';
    }

    return column;
  });
}