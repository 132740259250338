export const onColumnVisibleEvent = ({ columnDefinition, gridRef, onColumnVisible, params }) => {

  const minimumColumns = 2;
  const allColumnsLength = columnDefinition.length;
  const columnState = gridRef.current.api.getColumnState();
  const visibleColumns = columnState.filter(col => !col.hide).length;

  if (onColumnVisible) onColumnVisible(params);

  if (visibleColumns <= minimumColumns) {
    const state = columnState.map((col, colIndex) => {
      if (colIndex >= (allColumnsLength - minimumColumns)) col['hide'] = false;
      return col;
    });
    gridRef.current.api.applyColumnState({
      state,
      applyOrder: true, // APPLIES COLUMN ORDER
    });
  }
};