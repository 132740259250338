import { useState, useCallback } from "react";

const useBasePresentationService = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);

  const callApi = useCallback(async (url, method, body = null, headers = {}) => {

    const endpoint = `${process.env.REACT_APP_HOST_URL}${url}`

    setIsLoading(true);
    setError(null);
    setResponse(null);

    try {
      const userIdentity = JSON.parse(sessionStorage.getItem('userIdentity'));
      const options = {
        method,
        headers: {
          Authorization: `Bearer ${userIdentity.token}`,
          "Content-Type": "application/json",
          ...headers,
        },
      };

      if (body) {
        options.body = JSON.stringify(body);
      }

      const res = await fetch(endpoint, options);

      if (!res.ok) {
        throw new Error(`HTTP error! Status: ${res.status}`);
      }

      const data = await res.json();
      setResponse(data);
    } catch (err) {
      setError(err.message || "Something went wrong");
    } finally {
      setIsLoading(false);
    }
  }, []);

  return { isLoading, response, error, callApi };
};

export default useBasePresentationService;