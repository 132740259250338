import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

const createContactsStore = createWithEqualityFn((set) => ({
  viewContactAddForm: false,
  setViewContactAddForm: (data) => set(() => ({ viewContactAddForm: data })),
  viewContactEditForm: false,
  setViewContactEditForm: (data) => set(() => ({ viewContactEditForm: data })),
}));

const useContactsStore = (callback) => createContactsStore(callback, shallow);

export default useContactsStore;
