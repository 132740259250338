export const onGridReadyEvent = ({
  columnIsWithChildren,
  gridHasVerticalColumns,
  onGridReady,
  params,
}) => {
  if (onGridReady) onGridReady(params);

  if (columnIsWithChildren) {
    params.api.setGridOption('headerHeight', '30px');
  } else {
    params.api.setGridOption('headerHeight', gridHasVerticalColumns ? '110px' : '50px');
  }
};