const applyCellClassMethod = (params) => {
  /**
   * This function determines the CSS class for the cell in an ag-grid column based on the row's selection status.
   * If the row is selected, the cell background color is set to the specified selected row background color (--ag-selected-row-background-color).
   * If the row is not selected, the cell background color alternates between two designated colors (--op2mise-color-ash-gray) and --op2mise-color-light-gray.
   * Prevents overlapping of styles between the --ag-selected-row-background-color and the 'booked' auto cell styling.
   **/

  // Retrieve the computed style for the selected row background color from the document's root element
  const selectedRowBackgroundColor = getComputedStyle(document.documentElement).getPropertyValue('--ag-selected-row-background-color');

  // Helper function to create styling for booked columns
  const bookedColumnStyle = (backgroundColor) => {
    return {
      'textAlign': 'right', // Align text to the right
      'backgroundColor': backgroundColor, // Set the background color to the provided value
      'boxShadow': '3px 0px 4px rgba(0, 0, 0, 0.10) inset' // Add a subtle inset box shadow
    };
  }

  // Check if params are provided
  if (params) {
    // If the row is selected, apply the selected row background color styling
    if (params.node.isSelected()) {
      return { ...bookedColumnStyle(selectedRowBackgroundColor) };
    } else {
      // If the row is not selected, alternate background colors based on row index
      return params.rowIndex % 2 !== 0
        ? bookedColumnStyle('var(--op2mise-color-ash-gray)')
        : bookedColumnStyle('var(--op2mise-color-light-gray)');
    }
  }
}

export const applyShadesOnBookedColumn = ({ columnInstance, disableHighlightOnBookedColumn, setColumnIsWithChildren }) => {
  /**
   * On initial load, this function processes a list of columns to identify any column with a `field` containing 'booked'.
   * For these columns, it applies specific cell styles using `applyCellClassMethod` to apply two shades of gray and align text to the right.
   * If a column has children, it also applies these styles to the child columns.
   * 
   * @param {Array} columnInstance - The list of column objects to be processed.
   * @param {boolean} disableHighlightOnBookedColumn - Flag that overrides highlighting of booked columns.
   * @param {function} setColumnIsWithChildren - Function to set a flag indicating if columns with children exist.
   * @returns {Array} The updated list of columns with applied cell styles.
   **/

  return columnInstance.map((column) => {
    // Check if the column has children
    if (column.children && column.children.length) {
      // Set a flag indicating that columns with children exist
      setColumnIsWithChildren(true); // Consider moving this to a different function if it's unrelated

      // Process each child column
      column.children = column.children.map((child) => {
        // Check if the child's field includes 'book' and highlighting is not disabled
        if (child.field.includes('book') && !disableHighlightOnBookedColumn) {
          // Apply the cell style to the child column using the `applyCellClassMethod`
          child['cellStyle'] = applyCellClassMethod;
        }
        return child; // Return the updated child column
      });
    }

    // Check if the column's field includes 'book' and highlighting is not disabled
    if (column.field.includes('book') && !disableHighlightOnBookedColumn) {
      // Apply the cell style to the column using the `applyCellClassMethod`
      column['cellStyle'] = applyCellClassMethod;
    }

    return column; // Return the updated column
  });
}