import { ClearFilterTemplate } from '../../Templates';

export const applyClearAllFilter = ({ columnInstance }) => {
  /**
    * This function is designed to add a clear filter button to the last column in an ag-Grid configuration.
    * It iterates through the columns and applies a custom header component to the last column.
    * This is useful for enhancing the user experience by providing a consistent way to clear filters directly
    * from the column header, improving data interaction and visibility.
    */

    return columnInstance.map((column, columnIndex) => {
      if (columnInstance.length === columnIndex + 1) {
        return {
          ...column,
          headerComponent: (params) => ClearFilterTemplate(params),
        };
      }
      return column;
    });
}