export const gridRowSelectionHelper = (rowSelection, selectionColumnDef, enableMultipleRowSelection) => {
  return {
    rowSelectionProperties: {
      mode: enableMultipleRowSelection ? 'multiRow' : 'singleRow',
      checkboxes: false,
      headerCheckboxes: false,
      enableClickSelection: false,
      ...rowSelection,
      hideDisabledCheckboxes: false,
      enableSelectionWithoutKeys: false,
    },
    selectionColumnProperties: {
      width: 60,
      minWidth: 60,
      maxWidth: 60,
      pinned: "right",
      ...selectionColumnDef,
      sortable: false,
      suppressHeaderMenuButton: true,
    },
  }
};