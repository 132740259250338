/* eslint-disable import/no-cycle */
// import { create } from 'zustand';
import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

const useStore = createWithEqualityFn((set) => ({
    campaigns: null,
    setCampaigns: (data) => set(() => ({ campaigns: data })),
    originalInstructionDetails: null,
    setOriginalInstructionDetails: (data) => set(() => ({ originalInstructionDetails: data })),
    selectedCampaignId: null,
    setSelectedCampaignId: (data) => set(() => ({ selectedCampaignId: data })),
    markets: null,
    setMarkets: (data) => set(() => ({ markets: data })),
    selectedStations: null,
    setSelectedStations: (data) => set(() => ({ selectedStations: data })),
    originalSelectedStations: null,
    setOriginalSelectedStations: (data) => set(() => ({ originalSelectedStations: data })),
    resetRotationInstructionDataGrid: false,
    setResetRotationInstructionDataGrid: (data) => set(() => ({ resetRotationInstructionDataGrid: data })),
    viewInstructionsForms: false,
    setViewInstructionsForms: (data) => set(() => ({ viewInstructionsForms: data })),
}));

const useInstructionStore = (callback) => useStore(callback, shallow);

export default useInstructionStore;
