import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

const createOrdersStore = createWithEqualityFn((set) => ({
  viewOrderDetails: {
    show: false,
    id: null,
    orderId: null,
  },
  setViewOrderDetails: (data) => set(() => ({ viewOrderDetails: data })),
}));

const useOrdersStore = (callback) => createOrdersStore(callback, shallow);

export default useOrdersStore;
