export const applyTooltips = ({ columnInstance }) => {
  // Iterate through each column in the columnInstance array
  return columnInstance.map(column => {
    // Provide tooltips for each row/header cells

    // Use `headerName` if it exists, otherwise use the `field` attribute, converting it to uppercase.
    // Applicable only for header cells
    const displayName = column.headerName ? column.headerName.toUpperCase() : column.field.toUpperCase();

    // If the column has child columns, process each child column
    if (column.children && column.children.length) {
      column.children = column.children.map(child => {
        const childDisplayName = child.headerName ? child.headerName.toUpperCase() : child.field.toUpperCase();
        // This is for the header cell tooltips
        if (displayName.replace(/\s+/g, '').length) {
          // If `headerTooltip` is not defined, set it to the capitalized display name
          if (!child.hasOwnProperty('headerTooltip')) {
            child['headerTooltip'] = childDisplayName;
          } else {
            // Otherwise, capitalize the existing `headerTooltip` value
            child['headerTooltip'] = child.headerTooltip.toUpperCase();
          }
        } else {
          // Do not provide tooltip for Empty Headers
          child['headerTooltip'] = null;
        }
        
        // This is for the row cell tooltips
        // If `tooltipValueGetter` is not defined, provide a default function that returns the cell value
        if (!child.hasOwnProperty('tooltipValueGetter')) {
          child['tooltipValueGetter'] = (params) => params.value;
        }

        return child;
      });
    }

    if (displayName.replace(/\s+/g, '').length) {
      // If `headerTooltip` is not defined, set it to the capitalized display name
      if (!column.hasOwnProperty('headerTooltip')) {
        column['headerTooltip'] = displayName;
      } else {
        // Otherwise, capitalize the existing `headerTooltip` value
        column['headerTooltip'] = column.headerTooltip.toUpperCase();
      }
    } else {
      // Do not provide tooltip for Empty Headers
      column['headerTooltip'] = null;
    }

    // This is for the row cell tooltips
    // If `tooltipValueGetter` is not defined, provide a default function that returns the cell value
    if (!column.hasOwnProperty('tooltipValueGetter')) {
      column['tooltipValueGetter'] = (params) => params.value;
    }
    
    return column;
  });
}