import useBasePresentationService from "./useBasePresentationService";

export const useGetPresentationService = () => {
  const { isLoading, response, error, callApi } = useBasePresentationService();

  const getApi = (url, queryParams = {}, headers = {}) => {
    const queryString = new URLSearchParams(queryParams).toString();
    const endpoint = queryString ? `${url}?${queryString}` : url;
    callApi(endpoint, "GET", null, headers);
  };

  return { isLoading, response, error, getApi };
};
